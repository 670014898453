.favorite {
    min-height: 100vh;
    height: fit-content;
    overflow-x: scroll;
}

.favorite__box {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    justify-content: center;
    border-radius: 10px;
}

.favorite__title {
    color: #011210;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.3px;;
    padding: 20px 0;
}

.favorite__name {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
}

.favorite__item {
    width: 100%;
    max-width: 358px;
    border-radius: 10px;
    background: #FFFFFF;
    display: flex;
    padding: 8px;
}


.favorite__price {
    display: flex;
    gap: 5px;
    color: #000;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.favorite__price-line {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.5px;
    color: #888;
    text-decoration-line: line-through;

}

.favorite__btns {
    display: flex;
    align-items: center;
    margin-top: 15px;
    gap: 20px;

}

.favorite__content {
    display: flex;
    flex-direction: column;
    gap: 3px;
    padding: 10px;
}

.favorite__btn {
    max-width: 141px;
    width: 100%;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #0CE3CB;
    color: #FEFEFE;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;;
}

.favorite__photo {
    min-width: 142px;
    height: 152px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.favorite__photo img {
    max-width: 142px;
    height: 100%;
}

@media screen and (min-width: 780px) {
    .favorite{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        height: fit-content;
        overflow-x: unset;
        min-height: 50vh;

    }
    .favorite__box {
        gap: 20px;
        justify-content: flex-start;
    }

    .favorite__box{
        width: 100%;
    }

    .favorite__item{
        min-width: 50%;
        max-width: calc(50% - 20px);
    }

    .favorite__title{
        width: 100%;
        text-align: left;
    }

}
